<template>
    <div :class="['liveplayer', type === 'rtmp' && 'beforeReady']">
        <template v-if=" type === 'rtmp' ">
        <div class="playerarea"><video class="video-js vjs-default-skin vjs-big-play-centered" width="100%" height="100%" controls></video></div>
        <div class="player-loading"><i aria-hidden="true" class="fa fa-spinner"></i></div>
        </template>
        <div v-else class="playerarea">
            <div class="player"></div>
        </div>
    </div>
</template>
<style lang="scss">
// http://vjs.zencdn.net/5.19/video-js.min.css
@import './css/video-js.min.css';
.liveplayer{
	position:relative;
	overflow: hidden;
    background:#000
}
.liveplayer:before{
	display: block;
	content:'.';
	padding-top: 56.25%;
    line-height: 0;
	text-indent: -9999px;
	overflow: hidden;
}
.liveplayer .playerarea{
	position: absolute;
	width:100%;
	top:0;
	left:0;
    bottom:0;
    background:#000
}
.beforeReady{
    .playerarea{
        visibility: hidden!important;
    }
    .player-loading{
        display: flex;
    }
}

.player-loading{
    position: absolute;
    display: none;
    top:0;
    left:0;
    right:0;
    bottom:0;
    color: #fff;
    text-align: center;
    align-items: center;
    justify-content: center;
    .fa-spinner{
        font-size: 36px;
        line-height: 0;
        animation:rotate-data-v 2s linear infinite;
    }
}

@keyframes rotate-data-v {
	0% {
	transform:rotate(0deg)
	}
	to {
		transform:rotate(1turn)
	}
}

.video-js .vjs-tech {
  pointer-events: none;
}
</style>
<script>
 /***
  * docs https://docs.videojs.com/docs/api/player.html
  */
 const videoJsOptions = { 
    autoplay: true, //自动播放 
    language: 'zh-CN', 
    controls: false, //控制条 
    preload: 'auto', //自动加载 
    errorDisplay: true, //错误展示 
    controlBar: false,
    loop: false,
    flash: {
        swf: '//stc-bd.iqiyi.com/translate-app-main/video-js.swf'
    },
    muted: false,
    fluid: true, //跟随外层容器变化大小，跟随的是外层宽度
    textTrackDisplay: false, // 不渲染字幕相关DOM ,
    userActions: { 
        hotkeys: false //是否支持热键 
    }, 
    sources: [
        { 
            src: '',
            type: "rtmp/flv"
        } 
    ] 
}

const tsPlayerOption = {
    qpId: '664783923', //奇普id
    roomId: '664783923', //奇普id
    roomType: 1, // 0 体育，1 其他ppc
    ui:{
        fullPage: false, //网页全屏
        like: false //点赞
    },
    multiCamera: false, //是否有多机位
    canReplay: true, //是否可以事实回看 
    wmFlag:true, //是否有水印
    wmShowCode: 1, //水印位子 
    //wmImageUrl: 'a.jpg', //水印图片
    //videoBgImg: 'b.jpg'//播放器
};
                
export default {
    name:"LivePlayer",
    data(){
        return {
            options: {},
            videojs: null,
            player: null
        }
    },
    watch:{
        url(value){
            const { options, validatorUrl, player, playerInit } = this
            if(this.type !== "rtmp") return false
            if(validatorUrl(value) && value !== options.sources[0].src){
                if(!player){
                    playerInit()
                }else{
                    player.src(value)
                }
            }
        },
        qpId(value){
            this.playerInit()
        }
    },
    props:{
        type:{
            type: String,
            default: "rtmp"
        },
        qpId:{
            type: Number
        },
        url: {
            type: String,
            default: 'rtmp://goodbye/live/testwei'
        }
    },
    methods:{

        validatorUrl(value) {
            return /^rtmp:\/\//.test(value)
        },

        checkFlash(){
            var flashObj;
            try{
                flashObj= navigator.plugins['Shockwave Flash'];
            }catch(e){
                flashObj= new  ActiveXObject("ShockwaveFlash.ShockwaveFlash");
            }

            if(!flashObj){
                let dom = document.createElement('div')
                dom.innerHTML = '当前浏览器尚未运行flashplayer，<a href="//www.adobe.com/go/getflashplayer" target="_blank" style="color:#ffe300;">点击运行</a> <br/>如果点击运行无效请按照下面两个步骤操作<p style="text-align:center;margin-bottom:0px;margin-top:20px;color:#fff;font-size:18px;"><img src="//www.iqiyipic.com/qlive/fix/img/flash/tips1_2.png" style="margin-right: 10px;width: 290px;vertical-align: top;"><img src="//www.iqiyipic.com/qlive/fix/img/flash/tips2_2.png" style="width: 230px;vertical-align: top;"></p>'
                dom.style.cssText="position:absolute;top:0;left:0;right:0;bottom:0;z-index:10;background:#000;color:#fff;text-align:center;padding-top:20px;font-size:16px;line-height:20px"
                this.$el.querySelector('.video-js').appendChild(dom)
            }
            return flashObj ? true : false;
        },

        async tsPlayer(){
            const { qpId, options } = this
            const that = this
            let player
            let videoDom
            
            if(!qpId){
                return false
            }
            try{
                if(!window.__liveSDK__){ 
                    await import(/* webpackChunkName: "liveTsplayer" */ './livePlayerSdk')
                }
                const Player = await window.__liveSDK__({
                    platform: 'intl',
                    version: "0.0.5"
                }).then(ins=>{
                    return ins.load('player')
                });

                player= Player({
                    ...options,
                    ...{
                        container: this.$el.querySelector('.player'),
                        qpId,
                        roomId: qpId
                    }
                })

                /**
                 * 直播播放器不支持timeupdate,seeking,ended事件，现在直接绑在video上, 然后自己造这些事件
                 */
                let Events = {}
                player.PTW = {
                    on:(type, fn)=>{
                        if(!Events[type]){
                            Events[type]=[]
                        }
                        Object.prototype.toString.call(fn) === '[object Function]' && Events[type].push(fn)
                    },
                    emit:(type)=>{
                        if(Events[type]){
                           Events[type].forEach(item=>{
                               item()
                           }) 
                        }
                    },
                    off:(type, fn)=>{
                        if(Events[type]){
                            if(!fn){
                                Events[type]=null
                            }else{
                                Events[type]=Events[type].filter(item=>{
                                    return item===fn ? false : true
                                })
                            }
                        }
                    }
                }

                player.on("PLAYER_INITED", function(){
                    videoDom = that.$el.querySelector('.player video')
                    if(!videoDom) return false
                    that.$emit("playerInited" , this);
                    videoDom.addEventListener('timeupdate', (event) => {
                        player.PTW.emit('timeupdate')
                    })
                    videoDom.addEventListener('seeking', (event) => {
                        player.PTW.emit('seeking')
                    })
                    videoDom.addEventListener('ended', (event) => {
                        player.PTW.emit('ended')
                    })
                })

                /** 这个事件直播播放器还不支持 **/
                // player.on("timeupdate", function(){
                //     console.log("ts timeupdate")
                // })

                player.muted = function(state){
                    videoDom && (videoDom.muted= state)
                }

                player.play = function(){
                    videoDom && videoDom.play()
                }

                player.pause = function(){
                    videoDom && videoDom.pause()
                }

                player.seekTo=function(time){
                    let { currentTime, startTime } = player.player.getPlayerStatus()
                    player.seek( startTime +  time)
                }

                /**
                 * currentTime目前还不支持设置播放时间戳
                 * player.currentTime() 拿到时间戳
                 * player.currentTime(123) 设置一个时间戳，目前直播播放器不支持
                 */
                player.currentTime = function(time){
                    if(!time){
                        let result = 0
                        try{
                            const T = player.player.getCurrentTimeMs()
                            let { startTime } = player.player.getPlayerStatus()
                            if(T > 0 && startTime > 0 ){
                                result =  T / 1000 - startTime
                            }
                            return result
                        }catch(e){
                        }
                        return result
                    }else{
                        player.seekTo(time)
                    }
                }
                this.player = player
                return player
            }catch(e){
                throw new Error(e)
            }
        },

        async rtmpPlayer(){
            const { options, checkFlash, url, validatorUrl } = this
            const that = this
            if(!this.videojs){
                const { default: videojs } = await import(/* webpackChunkName: "liveplayer" */ './video.min')
                this.videojs = videojs
            }
            if(!validatorUrl(url)){
                return false
            }
            options.sources[0].src= url
            const player= this.videojs(
                this.$el.querySelector('video'),
                options
            )
            player.on('ready', function(){
                checkFlash()
                that.$el.classList.remove('beforeReady')
            })
            player.on('loadedmetadata', function() {
                that.$emit("playerInited" , this);
            })
            this.player= player
            return player
        },

        playerInit(){
            if(this.type === "rtmp" ){
                this.options =_ .cloneDeep(videoJsOptions)
                this.rtmpPlayer()
            }else{
                this.options =_ .cloneDeep(tsPlayerOption)
                this.tsPlayer()
            }
        }
    },

    async mounted(){
        this.playerInit()
    },

    destroyed(){
        if(this.type!=='ts'){
            if(this.player){
                this.player.dispose && this.player.dispose()
            }
        }else{
            this.player.player.destroy()
        }
        this.player= null
    }
}
</script>