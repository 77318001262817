<template>
<div class="patwords" :class="{loading: !init}">
	<div v-if="0" style="position:fixed;background:rgba(0,0,0,0.5);color:#fff;z-index:9999;padding:10px;border-radius:8px;bottom:20px;right:20px">
		timeByPX: {{timeByPX}} 
		<br />space：{{ space }}
		<br />step：{{ step }}
		<br />rulerBegin - rulerEnd = {{rulerBegin}} - {{rulerEnd}} = {{ rulerEnd - rulerBegin }}  
		<br />domWidth: {{ domWidth }} 
		<br />(rulerEnd - rulerBegin) / domWidth = {{ ((rulerEnd - rulerBegin) / domWidth).toFixed(2) }}
		<br />ruler data length：{{data.length}}
		<span onclick="this.parentNode.style.display='none'" style="position:absolute;top:10px;right:10px;cursor: pointer;">{{$t('ECHO_TRANSLATION_PLATFORM_1614765839550_568')}}</span>
	</div>
    <div class="patwordsCaption">
        <div class="cpl">{{timeFormat(currentTime,1)}}</div>
        <div class="cpr" v-if="enablePat">
			<span style="color:#f00;margin-right:10px">{{ !pageFocus ? $t('ECHO_TRANSLATION_PLATFORM_1614765881751_527'):'' }}</span>
			<el-button size="small" type="primary" v-if=" mode === 'video'" @click="patwordsStart" :disabled="isPating || subtitleData.length === 0 ">{{$t('ECHO_TRANSLATION_PLATFORM_1610619435409_158')}}(alt+z)</el-button>
			<el-button size="small" type="primary" @click="patwordsing" :disabled="!isPating">{{$t('ECHO_TRANSLATION_PLATFORM_1610619431747_356')}}(Enter)</el-button>
			<el-button size="small" type="primary" v-if=" mode === 'video'"  @click="patwordsEnd" :disabled="!isPating">{{$t('ECHO_TRANSLATION_PLATFORM_1610619439090_605')}}(alt+x)</el-button>
		</div>
    </div>
	<div class="patwordsMainWrap">
		<div class="patwordsMain">
			<div class="ruler" @click="setCurrentTime">
				<div v-for="(item, index) in data" :key="index" :style="{ width: space+'px' }" :class="{'mid': item.mid,'wt': item.wt, 'placeholder': item.type === 'placeholder'}" :data-id="item.id">
					<span class="rulerValue" v-if="item.wt" :style="{ marginLeft: space/2+'px' }">{{item.formatRight}}</span>
				</div>
			</div>
			<div class="subtitleList" :style="{ height: depth >=3 ? (depth*22 + 20) +'px': 'auto' }">
				<template v-for="(item, index) in subtitleDatas">
				<div class="subtitleItem" v-if="parseFloat(item.width) > 0" :key="index" :style="{width: item.width, left: item.left, zIndex: index+1,top:item.top+'px'}" :title="roleType === 0 ? item.originalDialogueContent : item.translateDialogueContent + '\nstart: '+item.start+'\nend: '+item.end">
					<span class="boundary" @mousedown="subtitleDarg($event, 'start', item)" ></span>
					<span class="boundary-mid" @mousedown="subtitleDarg($event, 'mid', item)">{{ roleType === 0 ? item.originalDialogueContent : item.translateDialogueContent }}</span>
					<span class="boundary" @mousedown="subtitleDarg($event, 'end', item)" ></span>
				</div>
				</template>
			</div>
			<div class="playSolid" v-show="playSolid" :style="{ transform: 'translateX('+progress+')', transition}" @mousedown="playSolidDrag"></div>
		</div>
	</div>
    <div class="patwordsActions" v-if=" mode === 'video'">
        <div class="act-item">
			<span class="sp">{{$t('ECHO_TRANSLATION_PLATFORM_1610619442611_548')}}</span>
			<el-button size="small" @click="showRuler(5000)" v-if="duration === 5000" type="primary">5s</el-button>
			<el-button size="small" @click="showRuler(5000)" v-else>5s</el-button>
			<el-button size="small" @click="showRuler(10000)" v-if="duration === 10000" type="primary">10s</el-button>
			<el-button size="small" @click="showRuler(10000)" v-else>10s</el-button>
		</div>
        <div class="act-item">
			<span class="sp">{{$t('ECHO_TRANSLATION_PLATFORM_1610619443018_74')}}</span>
			<el-button size="small" @click="ajust(-2000)" :disabled="rulerBegin === 0">{{$t('ECHO_TRANSLATION_PLATFORM_1610619446653_194')}} 2s</el-button>
			<el-button size="small" @click="ajust(2000)" :disabled="rulerEnd === totalTime">{{$t('ECHO_TRANSLATION_PLATFORM_1610619447603_526')}} 2s</el-button>
		</div>
    </div>
	<div class="patwords-mask" v-show="isPating" @click="patwordsMaskTip"><span class="tip" :style="tip">{{$t('ECHO_TRANSLATION_PLATFORM_1614766092413_9')}}</span></div>
	<div class="loading-content" v-if="!init">{{$t('ECHO_TRANSLATION_PLATFORM_1614696933587_554')}}</div>
</div>
</template>
<script>
const options = {
	init: false, // 组件是否可以开始初始化
	domReady: false, // 组件是否mounted且dom ready
	enablePat: true, // 是否启用拍词功能
	isPating: false, // 是否正在拍词
	subtitleData: [], // 字幕数据用于刻度尺上面的展示，PS：这里的数据按照字幕start排序
	subtitleDataPw:[], // 字幕数据用于拍词时， PS：这里的数据保持原数据的排序，即根据index排序
	data: [], // 刻度尺标的数据
	currentTime: 0, // 当前视频播放的时间
	duration: 0,// 当前展示的刻度显示的总秒数，基本上是根据页面的宽度自动算出能展示多少秒
	rulerBegin: 0,// 当前刻度的起始时间点
	rulerEnd: 6550,// 当前刻度的结束时间点
	caliperWidth: 2, // 游标指针的宽度, 单位像素
	playSolid: true, // 游标指针是否显示
	progress: 0, // 游标指针的位置,单位像素
	timeByPX: 0,// 一像素代表多少时长
	space: 8, // 一刻度占多少像素值
	step: 100, // 一刻度表示的毫秒数
	page: 1,
	domWidth: 0,
	transition: 'all 0.5s linear',
	depth: 1	
}
export default {
    data(){
        return {
			...options, 
			...{
				patwordsMain: null,
				tip: {
					left:0,
					top:0
				},
				patwordId: -1, // 用于标记当前正在拍的字幕的id
				resetPatwordId: false, //这个属性只有在live模式下才会启用，由于直播拍词字幕的内容是分段进来的，于是就有上一段拍拍完后但后一段的字幕数据还拿到传进来，于是就需要
				pageFocus: true, // 判断当前页面是否存在焦点
				pageFocusTimeId: null,
				tipTimeId : null,
				eventFn :'',
				videoPlayer: {},
				playEnded: false, // 视频是否播放结束
				playerSeeking: '',
				playerTimeupdate: ''
			}
		}
	},
	props:{
		mode:{
			type: String,
			default: "video",
			validator(value){
				/***
				 * 分类直播节目和点拨节目两种模式，两种模式的区别为：
				 * 直播节目没有totalTime， 点拨节目有，所有时间轴直播节目是要特殊处理的
				 * 直播节目开始起拍第一下播放器的时间戳永远是第一句字幕的开始时间，点播节目开始起拍第一下播放器的时间戳具体是哪一句字幕的开始或结束，有复杂的产品逻辑，具体wiki：http://wiki.qiyi.domain/pages/viewpage.action?pageId=633900106
				 */
				return ["live", "video"].includes(value) === true
			}
		},
		// 角色类型，决定显示源字幕还是翻译后的字幕
		// 字幕校对(全功能拍词模块) ： 0 
		// 字幕翻译(仅字幕时间调整功能) ： 1 
		// 字幕审核(仅字幕时间调整功能) ： 2
		// 字幕制作(全功能拍词模块) ： 3 
		roleType :{
			type: Number
		},
		// 是否开启拍词功能
		enablePatWord: {
			default: true,
			type: Boolean
		},
		player:{
			required: true,
			type: Object
		},
		value:{
			required: true,
			type: Array
		},
		totalTime:{
			required: true,
			type: Number
		}
	},
	computed:{
		subtitleDatas(){
			const { rulerBegin, rulerEnd, step, space, getLeftValue }  = this
			const subtitleDataCopy = _.cloneDeep(this.subtitleData)
			let result = []
			let pre = null
			let depths = [1]
			let depth = 1

			result = subtitleDataCopy.map( value=> {
				const { end, start } = value
				if(!end && !start) {
					/**
					 * 针对直播节目翻译只有字幕但是没有任何字幕时间戳的情况
					 */
					return value
				}
				let left
				let width
				let item = {
					lapped: false,
					top: 10
				 }

				value.pated && delete value.patwordsEdit  //已经拍过的，清除掉patwordsEdit属性

				if(end <= rulerBegin || start >= rulerEnd){
					left = false
				}else{
					if(start >= rulerBegin){
						left = getLeftValue(start)
						width = ((( end <= rulerEnd ? end : rulerEnd ) - start) / step) * space
						if(end > rulerEnd) item.break = "end";
					}else{
						if(end > rulerBegin){
							left = getLeftValue(rulerBegin)
							width = ( ( end - rulerBegin )/step ) * space
							item.break = "start"
						}else{
							left = false
						}
					}
				}

				if(left >= 0 && width){
					// 这边的width没有width > 0 来判断 是由于在拍词的情况下，一句字幕还没有拍完整时，拍的开始时间是允许被拍成大于结束时间的，这样width就变成负值了，但是拍词的patwordsEdit值是变化的，所以数据还是需要响应式的
					item.width = width + 'px'
					item.left = left + 'px'
				}else{
					delete value.width
					delete value.left
					delete value.break
				}

				/**
				 * 判断是否重叠
				 */
				if(pre && left >= 0 && left <= (parseFloat(pre.width) + parseFloat(pre.left))){
					pre.lapped = true
					item.lapped = true
					item.top = depth * 22 + 10
					depth = depth + 1
					depths.push(depth)
				}else{
					depth = 1
				}
				pre = item

				return {
					...value, 
					...item 
				}
			})

			this.depth = Math.max.apply([], depths)
			
			if(JSON.stringify(result) !== JSON.stringify(this.subtitleData)){
				/**
				 * this.subtitleData数据进来的时候按照字幕的start时间重新排序了，产品要求不能改变原来的排序，所以数据处理好了之后按照原来的index排序后再返回回去
				 */
				this.$emit("input", result.sort((v, v2)=>{
					return v.index - v2.index
				}))
			}
			return result.filter(v=>{
				return v.left ? true : false
			})
		}
	},
	watch:{
		value: {
			immediate: true,
			handler(v) {
				const { mode, patwordsStart, setPatwordId, resetPatwordId } = this
				this.subtitleData = _.cloneDeep(v).sort((v, v2)=>{
					return v.start - v2.start
				})
				this.subtitleDataPw = _.cloneDeep(v)
				if( mode === "live" ){
					patwordsStart(); // 直播模式下，主要播放一ready，就自动启动拍词
					if(resetPatwordId && setPatwordId()){
						this.resetPatwordId = false // 如果上一段字幕拍完后，新的字幕数据进来后，重新设置PatwordId
					}
				}
			},
			deep: true
		},
		enablePatWord: {
			immediate: true,
			handler(v) {
				this.enablePat = v
			}
		},
		player: {
			immediate: true,
			handler(v) {
				// 播放器实例化完后在初始化拍词模块
				if(v.play){
					if(this.init){
						this.dispose()
						this.domReady = true
					}
					this.videoPlayer = v
					this.mountedInit()
				}
			}
		}
	},
	mounted(){
		this.domReady = true
		if(!this.init && this.videoPlayer.play){
			this.mountedInit()
		}
	},
	destroyed(){
		this.dispose()
	},
    methods:{
		mountedInit(){
			if(!this.domReady) return false
			this.patwordsMain = this.$el.querySelector('.patwordsMain')
			this.domWidth = this.patwordsMain.offsetWidth;
			this.showRuler()
			this.playerActions()
			this.keyEvent()
			this.checkPageFocus()
			this.resize()
			//console.log( this.videoPlayer.duration() * 1000, this.totalTime ) // 有矿给的totalTime和播放器自己获取的duration()有误差，下次回归功能的时候再更改这个
			this.init = true
			this.mode === "live" && this.patwordsStart(); // 直播节目的话 自动开始拍词
		},

		/**
		 * 销毁
		 */
		dispose(){
			if(this.init){
				this.init = false
				this.domReady = false
				this.bus.$off("patwordsStart", {})
				this.bus.$off("patwordsEnd", {})
				if(this.videoPlayer.PTW){
					this.videoPlayer.PTW.off('timeupdate', this.playerTimeupdate)
					this.videoPlayer.PTW.off('seeking', this.playerSeeking)
					this.videoPlayer.PTW.off('ended', this.playerEnded)
				}
				this.videoPlayer.off('timeupdate', this.playerTimeupdate)
				this.videoPlayer.off('seeking', this.playerSeeking)
				this.videoPlayer.off('ended', this.playerEnded)
				this.enablePat && window.removeEventListener("keydown", this.eventFn);
				this.pageFocusTimeId && clearInterval(this.pageFocusTimeId) && (this.pageFocusTimeId = null);
				this.videoPlayer = null
			}
		},

        getObjPos:function(obj){
			let x =0;
			let y = 0;
            if (obj.getBoundingClientRect){
                var box = obj.getBoundingClientRect();
                if(box.left == 0 && box.top == 0)
                {
                    return;
                }
                var D = document.documentElement;
                x = box.left + Math.max(D.scrollLeft, document.body.scrollLeft) - D.clientLeft;
                y = box.top + Math.max(D.scrollTop, document.body.scrollTop) - D.clientTop;

            } else {
                for(; obj != document.body; x += obj.offsetLeft, y += obj.offsetTop, obj = obj.offsetParent );
            }
            return {x,y}
		},

		resize(){
			window.addEventListener("resize", ()=>{
				console.log(document.documentElement.clientWidth)
			})
		},

		playerActions(){
			const { mode, videoPlayer, transformByCurrentTime, totalTime } = this
			this.playerTimeupdate = function(){
				const currentTime = videoPlayer.currentTime() * 1000
				this.transition = 'all 0.5s linear'
				this.currentTime = mode === "video" && currentTime > totalTime ? totalTime : currentTime  //有矿接口提供的totalTime存在和播放器自己获取的时长不一致的情况
				transformByCurrentTime(this.currentTime, 'timeupdate')
			}.bind(this)

			this.playerSeeking = function(){
				const currentTime = videoPlayer.currentTime() * 1000
				this.currentTime = mode === "video" && currentTime > totalTime ? totalTime : currentTime  //有矿接口提供的totalTime存在和播放器自己获取的时长不一致的情况
				transformByCurrentTime(this.currentTime, 'seeking')
			}.bind(this)

			this.playerEnded = function(){
				this.playEnded = true
				this.patwordsEnd()
			}.bind(this)
			/**
			 * 兼容ts直播播放器
			 */
			if(videoPlayer.PTW){
				videoPlayer.PTW.on('timeupdate', this.playerTimeupdate)
				videoPlayer.PTW.on('seeking', this.playerSeeking)
				videoPlayer.PTW.on('ended', this.playerEnded)
			}
			videoPlayer.on('timeupdate', this.playerTimeupdate)
			videoPlayer.on('seeking', this.playerSeeking)
			videoPlayer.on('ended', this.playerEnded)
			
		},

		keyEvent(){
			if( !this.enablePat ) return false
			this.eventFn = function(event){
				var event = window.event || event;
				const { isPating } = this
				if(event.altKey === true){
					if(!isPating && event.keyCode === 90){
						this.patwordsStart()
					}
					if(isPating && event.keyCode === 88){
						this.patwordsEnd()
					}
				}
				if(isPating && event.keyCode === 13){
					this.patwordsing()
					event.preventDefault() // 防止鼠标点击拍词按钮后，焦点一直在拍词按钮上，这时候再按回车键拍词时导致的双重触发click和enter快捷键拍词	
				}
							
			}.bind(this)
			window.addEventListener("keydown", this.eventFn)
		},

		/***
		 * 点击拍词开始按钮后，根据当前的currentTime判断起拍第一句字幕的id
		 * return true or false  如果patwordId设置成功，则返回true，反之false
		 */
		setPatwordId(){
			const { subtitleDataPw, mode, currentTime } = this
			let result = _.cloneDeep(subtitleDataPw)
			let match = false
			result.forEach((v, index)=>{
				delete v.patwordsEdit; // 清除下patwordsEdit属性，防止之前拍过但是没有把单条字幕拍完整时产生的脏数据
				if( match || v.pated ) return false
				if(this.patwordId === -1 ){
					if( mode === "video" ){
						if( currentTime <= v.start ){
							if( index == 0 ){ //如果当前这句字幕前面没有其它字幕时
								v.patwordsEdit = "start"
								this.patwordId = v.id
							}else{
								result[index - 1].patwordsEdit = "end"
								this.patwordId = result[index - 1].id
							}
							match = true
						}
					}else{
						/**
						 * 在直播模式下，开始拍词后回车按下的第一下永远是第一句字幕的开始时间，后面依次类推
						 */
						v.patwordsEdit = "start"
						this.patwordId = v.id
						match = true
					}
				}
			})
			
			if(JSON.stringify(result) !== JSON.stringify(subtitleDataPw)){
				this.$emit("input", result.sort((v, v2)=>{
					return v.index - v2.index
				}))
			}
			return match
		},

		/***
		 * 拍词核心业务逻辑 wiki: http://wiki.qiyi.domain/pages/viewpage.action?pageId=633900106
		 */
		patwordsCore(currentTime){
			const { subtitleDataPw, patwordId, mode } = this
			let match = false
			let result = []
			result = subtitleDataPw.map((v, index)=>{
				const item = { ...v }
				if( match ) return item
				if(item.id === patwordId){
					item[item.patwordsEdit || "start"] = currentTime
					if(item.patwordsEdit === "start"){
						item.patwordsEdit = "end"
						this.patwordId = item.id
					}else{
						item.pated= true // 对于拍完的字幕做个标记
						delete item.patwordsEdit;
						this.$emit("patItemWordEnd", item)
						if(index + 1 <= subtitleDataPw.length - 1){
							let next = subtitleDataPw[index + 1]
							next.patwordsEdit = "start"
							this.patwordId = next.id
						}else{
							this.patwordId = -1
							if( mode === "video" ){
								/**
								 * 整个拍词结束
								 */
								this.$emit("patwordEnd", true)
							}else{
								this.resetPatwordId = true
							}
						}
					}
					match = true
				}
				return item
			})

			/**
			 * 更新刻度尺展示的数据
			 */
			this.subtitleData = result.sort((v, v2)=>{
				return v.start - v2.start
			})

			this.$emit("input", result.sort((v, v2)=>{
				return v.index - v2.index
			}))
			
		},

		patwordsStart(){
			const { videoPlayer, subtitleData } = this
			// 没有字幕数据时，禁用拍词
			if( subtitleData.length === 0 || this.isPating || !this.init )  return false
			this.isPating = true
			this.playEnded = false
			this.setPatwordId()
			videoPlayer.play()
			this.bus.$emit("patwords", this.isPating)
		},

		patwordsing(){
			const { currentTime, isPating, playEnded } = this
			// console.log("patwordsing", document.activeElement, new Date().getTime())
			isPating && !playEnded && this.patwordsCore(currentTime)
		},

		patwordsEnd(){
			if(this.isPating){
				const { subtitleData } = this
				let result = [ ]
				this.isPating = false
				this.patwordId = -1
				this.videoPlayer.pause()
				result = subtitleData.map((v)=>{
					let item = { ...v }
					delete item.pated
					delete item.patwordsEdit; // 清除下patwordsEdit属性，防止之前拍过但是没有把单条字幕拍完整时产生的脏数据
					return item
				})
				this.bus.$emit("patwords", this.isPating)
				if(JSON.stringify(result) !== JSON.stringify(subtitleData)){
					this.$emit("input", result.sort((v, v2)=>{
						return v.index - v2.index
					}))
				}
			}
		},

		patwordsMaskTip(){
			const { clientX, clientY } = event
			if(this.tipTimeId) clearTimeout(this.tipTimeId)
			this.tip = {
				display: "block", 
				left: clientX + 'px',
				top: clientY + 'px'
			}
			this.tipTimeId = setTimeout(()=>{
				this.tip = {
					display : "none"
			  }
			},800)
		},

		/**
		 * @time 毫秒
		 */
		timeFormat(time, type){
			const hour = Math.floor(time / (1000*60*60))
			const minute = Math.floor((time % (1000*60*60)) / (1000*60))
			const second = Math.floor((time % (1000*60)) / 1000)
			const millisecond = "0000" + Math.floor(time % 1000)
			const twodigit = (num) => num < 10 ? '0' + num : num
			const template = `${twodigit(hour)}:${twodigit(minute)}:${twodigit(second)}`
			return !type ? template : template +':'+ millisecond.slice(millisecond.length - 3)
		},

		/**
		 * 点击刻度，重置游标的位置
		 */
		setCurrentTime(){
			if( this.mode === "live" ) return false
			const { getObjPos, timeByPX , rulerBegin, patwordsMain, caliperWidth} = this
			const pointerX = document.documentElement.scrollLeft + event.clientX - getObjPos(patwordsMain).x - caliperWidth 
			this.currentTime = rulerBegin + pointerX * timeByPX
			this.progress = pointerX + 'px'
			this.videoPlayer.currentTime(this.currentTime/1000)
			// console.log("红线点击",`\npointerX=${pointerX}`,`\nthis.currentTime=${rulerBegin + pointerX * timeByPX}`)
		},

		/**
		 * 根据 currentTime 获取相对刻度尺的left值
		 */
		getLeftValue(currentTime){
			const {rulerBegin, rulerEnd, step, space} = this
			if(currentTime >= rulerBegin && currentTime <= rulerEnd){
				return ((currentTime - rulerBegin)/step) * space
			}
			return false
		},

		/**
		 * 根据播放器当前的播放currentTime值转换对应的红色进度条的坐标和刻度的rulerBegin和rulerEnd值
		 */
		transformByCurrentTime(currentTime, type){
			const { rulerBegin, rulerEnd, duration, getLeftValue, caliperWidth } = this
			this.playSolid = true
			/**
			 * 当currentTime不在[rulerBegin, rulerEnd]区间内的逻辑
			 */
			if( currentTime < rulerBegin || currentTime > rulerEnd ){
				/**
				 * 当播放timeupdate或seek时，根据currentTime时间来实现刻度条翻页功能
				 */				
				if( ["timeupdate","seeking"].includes(type)){
					this.page = Math.ceil(currentTime / duration)
					this.page = this.page <= 0 ? 1 : this.page
					this.$emit("beforeTurnPage", this.page)
					this.showRuler()
					this.$nextTick(()=>{
						this.$emit("afterTurnPage", this.page)
					})
				}
				/***
				 * 如果是调整模式时，currentTime保持不变，且不在范围之内的话就隐藏红色进度条
				 */
				if(type === 'ajust'){
					this.playSolid = false
				}
			}

			this.progress = (getLeftValue(currentTime) - caliperWidth) + 'px'
		},

		/**
		 * 当duration存在时，即按照duration的值调整刻度的范围，具体规则为：以当前播放器的currentTime为中心点，5s即为当前播放时间点的前2.5s和后2.5s，至于currentTime为0或是小于2.5s等临界点的话，那就特殊处理了
		 */
		showRuler(range){
			let { rulerBegin, rulerEnd, totalTime, page, step, space, currentTime, domWidth, duration, mode } = this;
			if( range === duration ) return false
			if( Number(range) > 0 ){
				space = domWidth / ( ( range / 1000 ) * 10 ) //基于当前的dom宽度，一刻度有多少像素值,为了保证准确定，一律Math.floor取整数
				if( currentTime >= range / 2 && totalTime - currentTime >= range / 2 ){
					rulerBegin = currentTime - range / 2
					rulerEnd = currentTime + range / 2
				}else if(currentTime < range / 2 && totalTime - currentTime >= range / 2){
					rulerBegin = 0
					rulerEnd = range
					currentTime = range / 2
				}else if(currentTime >= range / 2 && totalTime - currentTime < range / 2 ){
					rulerBegin = rulerEnd - range
					rulerEnd = rulerEnd
					currentTime = rulerEnd - range / 2
				}else{
					// 切换的时间范围大于视频的总长度了
					range = duration
				}
			}else{
				duration = Math.floor((domWidth / space)) * step  //计算当前dom的宽度按照目前的setp和space能展示多少秒的刻度
				rulerBegin = (page - 1) * duration
				rulerEnd = rulerBegin + duration
			}

			if( rulerBegin < 0 ){
				rulerBegin = 0
			}

			/**
			 * 只针对点播视频有totalTime的，直播节目的话就让刻度根据播放无限下去吧
			 */
			if( mode === 'video' && rulerEnd >= totalTime ){
				rulerEnd = totalTime
			}

			this.duration = range ? range : duration
			this.timeByPX = step / space //基于当前的dom宽度，一像素表示多少时长
			this.space = space
			this.currentTime = currentTime
			this.rulerBegin = rulerBegin
			this.rulerEnd = rulerEnd
			this.transition = "none"
			this.rulerRander()
			this.$nextTick(()=>{
				this.transformByCurrentTime(currentTime)
			})
		},

		/**
		 * 刻度展示数据获取，return 一个数组
		 */
        rulerRander(){
			let { rulerBegin, rulerEnd , timeFormat, step } = this
			if(Number(rulerBegin) >=0 || Number(rulerEnd) > 0 ) {
				let rulers =[]
				let id = Math.floor(rulerBegin / step)
				if(id >=0 ){
					rulers.push({
						mid: id % 5 === 0 && id % 10!==0 ? true : false,
						wt: id % 10 === 0 ? true : false,
						formatRight: timeFormat(rulerBegin),
						id,
						type: "placeholder"
					})
				}
				while(rulerBegin < rulerEnd){
					rulerBegin = rulerBegin + step;
					id = id +1
					rulers.push({
						formatLeft: timeFormat(rulerBegin - step),
						formatRight: timeFormat(rulerBegin),
						value: rulerBegin,
						mid: id % 5 === 0 && id % 10!==0 ? true : false,
						wt: id % 10 === 0 ? true : false,
						id
					})
				}
				this.data = rulers
			}
		},

		/**
		 * 向前或向后多少毫秒，调整时刻度保持初始的位置不变，目前代码的逻辑要改
		 * @time Number 单位毫秒，负数时则表示向前调整，正数时则表示向后调整
		 */
		ajust(time){
			let {duration, rulerBegin, rulerEnd, totalTime, currentTime } = this
			if( (rulerBegin === 0 && time < 0 ) || ( rulerEnd === totalTime && time > 0 )){
				return false
			}
			if(rulerBegin + time < 0){
				rulerEnd = rulerEnd - rulerBegin
				rulerBegin = 0
			}else if(rulerEnd + time > totalTime){
				rulerBegin = rulerBegin + (totalTime - rulerEnd)
				rulerEnd = totalTime
			}else if(rulerEnd - rulerBegin < duration){
				// 当视频播放到最后一页刻度时，且rulerEnd - rulerBegin是小于刻度总duration时
				rulerEnd= rulerBegin
				rulerBegin= rulerEnd - duration
			}else{
				rulerBegin = rulerBegin + time 
				rulerEnd = rulerEnd + time
			}
			this.rulerBegin = rulerBegin
			this.rulerEnd = rulerEnd
			this.rulerRander()
			this.$nextTick(()=>{
				this.transformByCurrentTime(currentTime, 'ajust')
			})
		},

		subtitleDarg(event, type, item){
			if( this.mode === "live" ) return false
			const {patwordsMain, getObjPos, timeByPX} = this
			const patwordsMainLeft = getObjPos(patwordsMain).x
			const tigger = event.target
			const that = this
			const itemTmp = { ...item }
			const parent = tigger.parentNode
			const { left, width } = itemTmp
			const mainWidth = ( this.data.length-1 ) * this.space
			let start = itemTmp.start
			let end = itemTmp.end
			let targetOffsetLeft
			this.dragging({
				tigger,
				start({ clientX }){
					parent.style.zIndex="9999"
					document.documentElement.style.cursor = type === "mid" ? "move" : "ew-resize"
					targetOffsetLeft = clientX - patwordsMainLeft - parseFloat(left)
				},
				move({ clientX }){
					let newLeft = clientX - patwordsMainLeft
					if(newLeft < 0){
						newLeft = 0
					}
					if(newLeft > patwordsMain.offsetWidth){
						newLeft = patwordsMain.offsetWidth
					}
					if(type === "start"){
						let adjustedValue = parseFloat(left) - newLeft 
						item.left = newLeft
						item.width = parseFloat(width) + adjustedValue
						start = itemTmp.start - adjustedValue * timeByPX 
					}
					if(type === "mid"){
						let adjustedValue
						newLeft = newLeft - targetOffsetLeft
						if( newLeft < 0 ){
							newLeft = 0
						}
						if((newLeft + parseFloat(width)) >= mainWidth){
							newLeft = mainWidth - parseFloat(width)
						}
						adjustedValue = newLeft - parseFloat(left)
						start = itemTmp.start + adjustedValue * timeByPX 
						end = itemTmp.end + adjustedValue * timeByPX

						item.left = newLeft
					}
					if(type === "end"){
						item.width = newLeft - parseFloat(left)
						end = itemTmp.start + item.width * timeByPX
					}	
					parent.style.width = item.width + 'px'
					parent.style.left = item.left + 'px'
				},
				end(){
					parent.style.zIndex=""
					document.documentElement.style.cursor = ""
					that.subtitleData = that.subtitleData.map(v=>{
						if(v.id === itemTmp.id){
							v.end = end
							v.start = start
						}
						return v
					})
				}
			}).dragstart(event)
		},

		playSolidDrag(){
			if( this.mode === "live" ) return false
			const {patwordsMain, getObjPos, rulerBegin, timeByPX, caliperWidth} = this
			const patwordsMainLeft = getObjPos(patwordsMain).x
			const that = this
			const tigger = event.target
			let pointerX = 0
			this.dragging({
				tigger,
				start(){
					tigger.style.transitionProperty= "none"
					document.documentElement.style.cursor = "pointer"
				},
				move({ clientX }){
					pointerX = clientX - patwordsMainLeft
					if(pointerX < 0){
						pointerX = -2
					}
					if(pointerX > patwordsMain.offsetWidth){
						pointerX = patwordsMain.offsetWidth
					}
					that.progress = pointerX + 'px'
					that.currentTime = rulerBegin + (pointerX + caliperWidth) * timeByPX
				},
				end(){
					tigger.style.transitionProperty= "all"
					document.documentElement.style.cursor = ""
					that.currentTime = rulerBegin + (pointerX + caliperWidth) * timeByPX  // +caliperWidth 游标刻度的拖标的宽度为caliperWidth，不加上的会产生caliperWidth的误差
					that.videoPlayer.currentTime(that.currentTime/1000)
				}
			}).dragstart(event)
		},

		dragging(tigger){
			class Drag {
				constructor({tigger, start, move, end}){
					const { dragstart, dragmove, dragend } = this
					this.tigger = tigger
					this.dragging = false
					this.start = start || function(){}
					this.move = move || function(){}
					this.end = end || function(){}
					this.dragstartHandler = dragstart.bind(this)
					this.dragmoveHandler = dragmove.bind(this)
					this.dragendHandler = dragend.bind(this)
					// tigger.addEventListener("mousedown", this.dragstartHandler);
				}
				dragstart(e) {
					e.preventDefault();
					const { clientX, clientY } = e
					this.dragging = true
					document.documentElement.style.userSelect="none"
					window.addEventListener("mousemove", this.dragmoveHandler, false);
					window.addEventListener("mouseup", this.dragendHandler, false);
					this.start.call(this, { clientX: document.documentElement.scrollLeft + clientX, clientY: document.documentElement.scrollTop + clientY})
				}
				dragmove(e) {
					e.preventDefault();
					if (this.dragging) {
						const { clientX, clientY } = e
						this.move.call(this, { clientX: document.documentElement.scrollLeft + clientX, clientY: document.documentElement.scrollTop + clientY })
					}
				}
				dragend() {
					this.dragging = false
					document.documentElement.style.userSelect=""
					this.tigger.removeEventListener("mousedown", this.dragstartHandler);
					window.removeEventListener("mousemove", this.dragmoveHandler, false);
					window.removeEventListener("mouseup", this.dragendHandler, false);
					this.end.call(this)
				}
			}
			return new Drag(tigger)
		},
		checkPageFocus(){
			this.pageFocusTimeId && clearInterval(this.pageFocusTimeId)
			this.pageFocusTimeId = setInterval(()=>{
				this.pageFocus = document.hasFocus() ? true : false
			},300)
		}
    }
}
</script>
<style lang="scss" scoped>
.loading{
		position: relative;
	> div{
		visibility: hidden;
	}
	.loading-content{
		position: absolute;
		top:0;
		right:0;
		left:0;
		bottom:0;
		display: flex;
		justify-content: center;
		align-items: center;
		visibility: visible;
	}
}
.patwords{
	font-size:12px;
	border: 1px solid #e8e8e8;
	border-radius: 5px 5px 0 0;
	box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
	margin:16px 7px 7px;
	.el-button--small{
		padding: 6px 15px;
	}
	.patwordsCaption{
		display: flex;
		padding: 6px 10px;
		justify-content: space-between;
		border-bottom: 1px solid #e8e8e8;
		.cpl{
			display: flex;
			align-items: center;
		}
		.cpr{
			position: relative;
			z-index: 1001;
		}
	}
	.ruler{
		position: relative;
		width: 100%;
		display: flex;
		height: 30px;
		align-items: flex-end;
		border-bottom:1px solid #999;
		user-select: none;
		cursor: url(../../assets/img/handcursor.png) 8 0, pointer;
		div{
			position: relative;
			flex: 0 0 auto;
			width:10px;
			height: 4px;
			border-right:2px solid #999;
		}
		.placeholder{
			bottom:0;
			left:-2px;
			width:2px!important;
			position: absolute;
			&:after{
				position: absolute;
				content: ".";
				width:2px;
				height: 1px;
				line-height: 0;
				left:0;
				bottom:-1px;
				text-indent: -999px;
				background: #999;
				overflow: hidden;
			}
		}
		.mid{
			height: 8px;
		}
		.wt{
			height: 12px;
		}
		.rulerValue{
			position: absolute;
			top:-18px;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	.subtitleList{
		position: relative;
		min-height: 60px;
	}
	.subtitleItem{	
		position: absolute;
		top:10px;
		width:190px;
		height: 20px;
		left: 0;
		line-height: 20px;
		background: rgba(24,115,200,.8);
		padding:0 4px;
		color: #fff;
		text-overflow: ellipsis;
		box-sizing: border-box;
		transition: top 0.5s linear;
		overflow: hidden;
		&:hover{
			background:#690
		}
		.boundary{
			position: absolute;
			top:0;
			left:0;
			bottom:0;
			width:4px;
			cursor: ew-resize;
			&:last-child{
				left:auto;
				right:0;
			}
		}
		.boundary-mid{
			display: block;
			height: 20px;
			cursor: move;
		}
	}
	.patwordsMainWrap{
		padding: 0 27px;
	}
	.patwordsMain{
		position: relative;
		padding-top:20px;
		.playSolid{
			position: absolute;
			left: 0;
			top: 10px;
			line-height: 0;
			bottom:0;
			width:2px;
			background: #f00;
			transform: translateX(0);
			z-index: 111;
			&:before{
				position: absolute;
				left: 50%;
				top: -10px;
				transform: translateX(-50%);
				width: 8px;
				height: 10px;
				content: ".";
				text-indent: -999px;
				background: #f00;
				overflow: hidden;
			}
			&:after{
				position: absolute;
				left:-3px;
				top: 0;
				width:0;
				height: 0;
				content: ".";
				text-indent: -999px;
				border-bottom:4px dashed transparent;
				border-right:4px dashed transparent;
				border-left:4px dashed transparent;
				border-top:4px solid #f00;
				overflow: hidden;
			}
			&:hover{
				cursor: pointer;
			}
		}
	}
	.patwordsActions{
		display: flex;
		padding:10px;
		line-height: 26px;
		border-top: 1px solid #e8e8e8;
		user-select: none;
		.sp{
			margin-right:10px;
		}
		.act-item{
			display: flex;
			margin-right:30px;
		}
	}
	.patwords-mask{
		position: fixed;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background: rgba(0,0,0,0);
		z-index: 1000;
		.tip{
			position: fixed;
			display: none;
			background:rgba(0,0,0,0.5);
			color: #fff;
			padding:5px 8px;
			z-index: 1002;
		}
	}
}
</style>